@use '~@xpo/ngx-core/styles/variables' as *;

.p5 {
  padding: 5px;
}

.pr10 {
  padding-left: 10px;
}

.success-submission {
  background-color: #4caf50 !important;
  color: white !important;
  padding: 10px !important;
}

.error-submission {
  background-color: red !important;
  color: white !important;
  padding: 10px !important;
}

@mixin user-status {
  font-weight: $xpo-fontWeight--bold;
  font-size: $xpo-fontSize--small;
  text-align: center;
  width: 75px;
  color: white;
}

.user-active-status {
  @include user-status;
  background-color: $xpo-green--400;
}

.user-inactive-status {
  @include user-status;
  background-color: $xpo-orange--400;
}

.mat-form-field-appearance-legacy {
  div.mat-form-field-wrapper {
    margin-top: 1em;
    margin-bottom: 1em;
  }
}

// TODO: remove
.xpo-MultiSelectAutocompleteFilter {
  .xpo-FilterContainer-content {
    width: 620px !important;
    flex-direction: column !important;

    .mat-chip {
      font-size: smaller;
    }
  }

  .mat-checkbox-label {
    font-size: small;
  }
  .xpo-MultiSelectAutocompleteFilterSelectedOptions-header {
    margin: 5px !important;
    height: 25px !important;
  }

  .xpo-FilterContainer-optionsContainer {
    max-height: 160px !important;
    overflow-y: scroll !important;
  }
}

// TODO: this should be the styles that are put in place when [drawerFullHeight]="true"
.xpo-Board {
  .xpo-Board-dataDrawer-drawer {
    height: calc(100vh - #{$ngx-header-height});
    top: $ngx-header-height;
    position: fixed;
  }
}

// TODO: move to core?
.xpo-DialogContent {
  .mat-dialog-content {
    max-height: unset;
  }
}
.xpo-ResponsiveLoadingSpinner--absolute {
  position: absolute;
  z-index: 9999;
}
